import React, { useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { useDispatch } from 'react-redux';
import { useNavigation } from '@react-navigation/native';

import { default as Text } from '../../components/AppText';
import { startNewSurveyEntry } from './firestopSlice';
import Button from '../../components/Button';

const FirstVisitSurveyEntryQuestions = ({ surveyId, numOfRepairs }) => {
  const dispatch = useDispatch();
  const navigation = useNavigation();
  const [section, setSection] = useState(0);

  const handlePreviousElementsYes = () => {
    // Use previous elements
		dispatch(startNewSurveyEntry({ surveyId, usePreviousElements: true }));
		navigation.navigate('FireStopElements');
  };

  const handleFailedElementsWithRepairsYes = () => {
    // Use previous failed elements with outstanding repairs
		dispatch(startNewSurveyEntry({ surveyId, usePreviousElements: true, useOnlyOutstandingRepairs: true }));
		navigation.navigate('FireStopElements');
  };

  const handlePreviousElementsNo = () => {
    if (section === 0 && numOfRepairs > 0) {
      setSection(1);
    } else {
      // Start with no elements
      dispatch(startNewSurveyEntry({ surveyId, usePreviousElements: false }));
      navigation.navigate('FireStopElements');
    }
  };

  if (section === 1) {
    return (
      <View>
        <View style={styles.textContainer}>
          <View>
            <Text variant="bold" size="xl">WARNING! You have {numOfRepairs} outstanding repairs on the previous survey.</Text>
          </View>
          <View style={{ marginTop: 12 }}>
            <Text variant="bold" size="xl">Would you like to add the corresponding breaches to your new survey?</Text>
          </View>
        </View>
        <View style={styles.buttonContainer}>
          <Button title="Yes" variant="secondary" onPress={handleFailedElementsWithRepairsYes} style={{ marginRight: 16 }} />
          <Button title="No" variant="secondary" onPress={handlePreviousElementsNo} />
        </View>
      </View>
    );
  }

  return (
    <View>
      <View style={styles.textContainer}>
        <View>
          <Text variant="bold" size="xl">You are starting a new survey.</Text>
        </View>
        <View style={{ marginTop: 12 }}>
          <Text variant="bold" size="xl">Would you like to use the breaches from your previous survey as a starting point?</Text>
        </View>
      </View>
      <View style={styles.buttonContainer}>
        <Button title="Yes" variant="secondary" onPress={handlePreviousElementsYes} style={{ marginRight: 16 }} />
        <Button title="No" variant="secondary" onPress={handlePreviousElementsNo} />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  textContainer: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 24
  }
});

export default FirstVisitSurveyEntryQuestions;