import { useState } from 'react';
import { View, Text, ActivityIndicator } from 'react-native';
import * as ImagePicker from 'expo-image-picker';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import Localbase from 'localbase';

import Button from '../../components/Button';
import { colors } from '../../constants/theme';
import { StyleSheet } from 'react-native';

const db = new Localbase('firestop');

const RepairImageUpload = ({ setPhotograph }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const fileTypes = ['image/jpeg', 'image/jpg', 'image/gif', 'image/png'];

    const handleUpload = async () => {
        try {
            setError(null);
            setIsLoading(true);

            const result = await ImagePicker.launchImageLibraryAsync({
                mediaTypes: ImagePicker.MediaTypeOptions.Images,
                allowsEditing: true,
                aspect: [4, 3],
                quality: 1,
            });

            if (result.canceled) {
                throw new Error('Cancelled');
            }

            let [, mimeType] = result.uri.split(';')[0].split(':');

            if (fileTypes.find((type) => type == mimeType) === undefined) {
                throw new Error('Incompatible File Type');
            }

            const [photo] = result.assets;
            let resize = {};

            if (photo.width >= photo.height) {
                resize = { width: 1000 };
            } else {
                resize = { height: 1000 };
            }

            const compressedPhoto = await manipulateAsync(
                photo.uri,
                [{ resize }],
                {
                    compress: 0.8,
                    format: SaveFormat.JPEG,
                }
            );

            db.collection('repair-photos')
                .add({
                    photograph_type: 'repair_photograph',
                    photograph_uri: compressedPhoto.uri,
                    width: compressedPhoto.width,
                    height: compressedPhoto.height,
                })
                .then((res) => {
                    let newImage = {
                        id: res.data.key,
                        photograph_type: 'repair_photograph',
                    };
                    let result = newImage;

                    setPhotograph(result);
                });
        } catch (e) {
            setError(e.message);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <View style={styles.container}>
            <Button
                title={
                    isLoading ? (
                        <ActivityIndicator color='#FFFFFF' />
                    ) : (
                        'Choose a file'
                    )
                }
                variant='secondary'
                size='sm'
                onPress={handleUpload}
                disabled={isLoading}
            />
            {error && (
                <Text style={{ marginTop: 10, color: colors.error }}>
                    {error}
                </Text>
            )}
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 10,
    },
});

export default RepairImageUpload;
