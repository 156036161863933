import { useState } from 'react';
import { Image, ScrollView, View, StyleSheet } from 'react-native';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesome5 } from '@expo/vector-icons';
import { format } from 'date-fns';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import { colors, padding } from '../constants/theme';
import Layout from '../containers/Layout';
import { selectUser } from '../features/auth/authSlice';
import { deleteSurvey, selectEntryBySurveyId, selectSurveyById, setSurvey, startNewSurveyEntry, resumeSurvey, deleteEntry, selectRepairs } from '../features/firestop/firestopSlice';
import Total from '../assets/no-fire.svg';
import Modal from '../components/Modal';
import Trash from '../components/Trash';
import { useEffect } from 'react';
import { calculateDeadline } from '../functions/calculations';

const FireStopSurveyDashboardScreen = () => {
	const route = useRoute();
	const dispatch = useDispatch();
	const navigation = useNavigation();

	const surveyId = route.params.surveyId;

	const user = useSelector(selectUser);
	const repairs = useSelector(selectRepairs);
	const survey = useSelector((state) => selectSurveyById(state, surveyId));

	const handleBack = () => {
		navigation.navigate('FireStopDashboard');
	};
	const [modal, setModal] = useState(null);
	const [modalDetails, setModalDetails] = useState(null);

	const entries = useSelector((state) => selectEntryBySurveyId(state, surveyId));

	const filteredEntries = entries.sort(
		(a, b) => new Date(b.created) - new Date(a.created)
	);

	const latestEntry = filteredEntries[0];
	const nextDue = survey?.frequency !== 'one off' ? latestEntry?.completed ? calculateDeadline(survey.created, latestEntry.completed, survey.frequency) : null : null;
	const hasElements = latestEntry?.elements.length > 0;

	const handleStartNewSurvey = () => {
    const numOfRepairs = Object.values(repairs).filter((repair) => {
      if (repair.status === 'outstanding' && repair.entry === latestEntry.id) {
        const entry = entries.find((entry) => entry.id === repair.entry);
        
        if (entry) {
          return true;
        }
      }
      return false;
    }).length;

		navigation.navigate('FireStopElements', { survey: survey.id, type: 'first_visit', has_elements: hasElements ? 'yes' : 'no', num_of_repairs: numOfRepairs });
	};

	const handleRepairs = () => {
		dispatch(setSurvey(surveyId));
		navigation.navigate('FireStopRepairs');
	};

	const handleViewSummary = (entryId) => {
		navigation.navigate('FireStopStack', {
			screen: 'FireStopStats',
			params: { surveyId: survey.id, entryId },
		});
	};

	const handleConfirm = (details) => {
		setModal(null);
		setModalDetails(null);

		switch(details.type)
		{
			case 'entry':
				dispatch(deleteEntry(details.id));
			break;

			case 'survey':
				
				dispatch(deleteSurvey(survey.id));
				navigation.navigate('FireStopDashboard');

			break;
		}
	};

	const handleResumeOrPDF = (entryID, surveySecretKey, completed) => {
		if (completed) {
			// send to PDF
      if (surveySecretKey) {
				window.open(`https://firestop.webglu35.co.uk/report/index.php?esk=${surveySecretKey}&csk=${user.secret_key}`, '_blank').focus();
			}
		} else {
			dispatch(resumeSurvey({ entryId: entryID }));
			
			navigation.navigate('FireStopElements');
		}		
	};

  useEffect(() => {
    if (!survey) {
			navigation.navigate('FireStopDashboard');
    }
  }, [survey]);

  if (!survey || survey.deleted) {
		return (
			<Layout>
				<ScrollView style={styles.container}>
					<View style={styles.header}>
						<View>
							<Text>No survey found</Text>
						</View>
            <Button title='Back' variant='black' onPress={handleBack} />
					</View>
				</ScrollView>
			</Layout>
		);
	}

  return (
    <Layout>
      <ScrollView style={styles.container}>
        <View style={styles.header}>
          <Text variant='heading' size='xl'>Survey: {survey.surveyName}</Text>
          <Button title='Back' variant='black' onPress={handleBack} />
        </View>
        <View style={[styles.header, { marginTop: 16 }]}>
          <View style={styles.headerRow}>
            {nextDue ? <Button title='Start New Survey' variant='secondary' onPress={handleStartNewSurvey} style={{ marginRight: 10 }} /> : null}
            <Button title='View Repairs' variant='black' onPress={handleRepairs} />
          </View>
          <View>
            <Button title={<Trash />} variant='transparent' onPress={() => {setModal("confirm");setModalDetails({"type":"survey"})}} />
          </View>
        </View>
        {survey?.frequency !== 'one off' ? (
          <View style={[styles.surveysDue, { marginTop: 32 }]}>
            <Text variant='heading' size='lg' style={{ marginRight: 8 }}>Survey Next Due:</Text>
            <Text>{(!!nextDue ? format(nextDue, 'dd/MM/yyyy') : 'Still in progress' )}</Text>
          </View>
        ) : null}
        <View style={{ marginTop: 32 }}>
          <Text variant='heading' size='lg'>Previous Surveys</Text>
        </View>
        <View style={[styles.row, { marginTop: 16 }]}>
          <View style={styles.date}>
            <Text>Date</Text>
          </View>
          <View style={styles.total}>
            <Image source={Total} style={{ width: 24, height: 24 }} />
          </View>
          <View style={styles.failed}>
            <FontAwesome5 name='times' size={24} color={colors.error} />
          </View>
          <View style={styles.passed}>
            <FontAwesome5 name='check' size={24} color={colors.success} />
          </View>
          <View style={styles.status}>
            <Text>Status</Text>
          </View>
          <View style={styles.actions}></View>
        </View>
        <View>
          {filteredEntries.map((entry) => {
            const filteredElements = entry.elements.filter(
              (element) => element.deleted === 0
            );
            const totalElements = filteredElements.length;
            const passedElements = filteredElements.reduce(
              (acc, current) => (current.status === 'passed' ? acc + 1 : acc),
              0
            );
            const failedElements = filteredElements.reduce(
              (acc, current) => (current.status === 'failed' ? acc + 1 : acc),
              0
              );

            return (
              <View key={entry.id} style={[styles.row, { backgroundColor: colors.white }]} >
                <View style={styles.date}>
                  <Text>{format(new Date(entry.created), 'dd/MM/yyyy')}</Text>
                </View>
                <View style={styles.total}>
                  <Text>{totalElements}</Text>
                </View>
                <View style={styles.failed}>
                  <Text>{failedElements}</Text>
                </View>
                <View style={styles.passed}>
                  <Text>{passedElements}</Text>
                </View>
                <View style={styles.status}>
                  <Text>{entry.status}</Text>
                </View>
                <View style={styles.actions}>
                  <Button title={(entry.status === "Complete"? 'PDF' : 'Resume')} variant='secondary' size='sm' onPress={() => handleResumeOrPDF(entry.id, entry?.entry_secret_key, (entry.status === "Complete"))} style={{ marginRight: 16 }} />
                  <Button title='View' variant='secondary' size='sm' onPress={() => handleViewSummary(entry.id)} />
                  <Button icon={<Trash />} variant='transparent' onPress={() => {setModal("confirm");setModalDetails({"type": "entry", "id": entry.id})}} />
                </View>
              </View>
            );
          })}
        </View>
        <Modal type="confirmation" showModal={modal === "confirm"} onConfirmation={() => handleConfirm(modalDetails)} onCancel={() => setModal(null)}>
          <Text>Are you sure you want to delete?</Text>
        </Modal>
      </ScrollView>
    </Layout>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingTop: padding.xxl,
    paddingBottom: padding.xl,
  },
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingVertical: padding.xxl,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  headerRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
    paddingVertical: padding.sm,
    paddingHorizontal: padding.md,
  },
  surveysDue: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  date: {
    width: '20%',
  },
  total: {
    width: '10%',
    alignItems: 'center'
  },
  failed: {
    width: '10%',
    alignItems: 'center'
  },
  passed: {
    width: '10%',
    alignItems: 'center'
  },
  status: {
    width: '17.5%',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  actions: {
    width: '32.5%',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
});

export default FireStopSurveyDashboardScreen;
