
import { useState, useEffect } from 'react';
import { View } from 'react-native';
import DropDownPicker from 'react-native-dropdown-picker';

import { default as Text } from '../components/AppText';
import Button from '../components/Button';
import PinMe from '../components/PinMe';
import { getPhotoFromDB } from '../features/firestop/ElementPhotographThumbnail';
import { globalStyles } from '../styles/GlobalStyles';

const FloorPlan = ({ styles, floorplan, pin, updatePin, toggleFloorplan }) => {
	const [currentFloorplan, setFloorplan] = useState(null);
	const [planIndex, setPlanIndex] = useState(0);
	const [selectOpen, setSelectOpen] = useState(null);
	const [currentPin, setCurrentPin] = useState(pin);

	useEffect(() => {
		getPhotoFromDB('images', floorplan[planIndex]?.db_id, (data) =>
		{
			setFloorplan(data);
		});
	}, [planIndex]);

	useEffect(() => {
		if (pin) {
			if (floorplan[pin[3]]) {
				setPlanIndex(pin[3]);
			} else {
				setPlanIndex(Object.keys(floorplan)[0]);
			}
		} else {
			setPlanIndex(Object.keys(floorplan)[0]);
		}
	}, [pin]);

	if(currentFloorplan == null)
	{
		return <Text>Loading</Text>;
	}
	
	let maxImageCount = floorplan.length - 1;

	function putPin(Array_Pin)
	{
		setCurrentPin([ ...Array_Pin, floorplan[planIndex].id ]);
	}
	
	/*function changePlan(Integer_Adjustment)
	{
		let current = planIndex + Integer_Adjustment;

		if(current < 0)
		{
			current = 0;
		}
		else if(current > maxImageCount)
		{
			current = maxImageCount;
		}

		setPlanIndex(current);
	}*/

	return(
		<View>
			<View>
				<Text variant='heading' size='xl'>Update survey breach marker pin</Text>
			</View>
			<View style={{...styles.formContainer, paddingHorizontal: 0 }}>
				{(Object.entries(floorplan).length > 1 ? <View style={{...styles.formContainer, margin: 0, padding: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', zIndex: 1 }}>
					<View style={{...styles.formContainer, flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginTop: 0, paddingHorizontal: 0, paddingVertical: 0, zIndex: 1}}>
						<Text>Current Floorplan</Text>
						<View style={{ marginTop: 8, marginLeft: 16, minWidth: 200 }}>
							<DropDownPicker placeholder='Please select a floorplan' value={planIndex.toString()} style={globalStyles.dropdown} dropDownContainerStyle={globalStyles.dropdownItem} items={Object.entries(floorplan).map((plan) => { return { "label": plan[1].file_name, "value": plan[0] }})} setValue={setPlanIndex} open={selectOpen} setOpen={setSelectOpen} />
						</View>
						{ /* <Button variant={planIndex <= 0 ? "" : ""} title="-" onPress={() => changePlan(-1)} />
						<Button variant={planIndex > maxImageCount ? "" : ""} title="+" onPress={() => changePlan(+1)} /> */ }
					</View>
				</View> : <></>) }
				<PinMe image={currentFloorplan.photograph_uri} imageDimensions={{ width: currentFloorplan.width, height: currentFloorplan.height }} pin={(currentPin != null && currentPin[3] === floorplan[planIndex].id ? currentPin : null)} updatePin={putPin} centerOnPin={true} />
				<View style={{flexDirection: 'row', marginTop: 20, paddingHorizontal: 16 }}>
					<Button title="Close" onPress={() => toggleFloorplan(false)} />
					<Button style={{ marginLeft: 16 }} title="Save & Close" variant="secondary" onPress={() => {updatePin(currentPin); toggleFloorplan(false); }}/>
				</View>
			</View>
		</View>
	)
};


export default FloorPlan;