import { useEffect, useState } from 'react';
import { View, StyleSheet, ActivityIndicator } from 'react-native';
import { useDispatch, useSelector } from 'react-redux';

import { default as Text } from '../../components/AppText';
import Button from '../../components/Button';
import Modal from '../../components/Modal';
import {
  deleteOrganisation,
  saveOrganisation,
  selectOrganisations,
} from '../organisations/organisationsSlice';
import { decreaseItems, removeSyncItem, resetItems, setItems } from './syncSlice';
import { postRepairSync, postSurvey, postSurveyEntry, selectRepairs } from '../firestop/firestopSlice';
import { addNewCost, deleteCost, updateCost } from '../costs/costsSlice';


const SyncPopup = ({ showSyncPopup, setShowSyncPopup }) => {
  const dispatch = useDispatch();
  const [numOfItems, setNumOfItems] = useState(0);
  const { sync, itemsToSync } = useSelector((state) => state.sync);
  const organisations = useSelector(selectOrganisations);
  const repairs = useSelector(selectRepairs);
  const { costs } = useSelector((state) => state.costs);
  const { surveysEntries } = useSelector((state) => state.firestop);

  const handleSyncAll = () => {
    dispatch(setItems(numOfItems));

    Object.keys(sync).forEach((group) => {
      sync[group].forEach((syncId) => {
        switch (group) {
          case 'organisations':
            const organisationToSync = organisations.find(
              (organisation) => organisation.id === syncId
            );
    
            if (organisationToSync) {
              if (String(syncId).includes('NEW') || organisationToSync.deleted === 0) {
                dispatch(saveOrganisation(organisationToSync))
                  .unwrap()
                  .then((res) => {
                    dispatch(removeSyncItem({ type: group, id: syncId }));
                  })
                  .catch((err) => console.log(err))
                  .finally(() => dispatch(decreaseItems()));
              } else if (organisationToSync.deleted === 1) {
                dispatch(deleteOrganisation(syncId))
                  .unwrap()
                  .then((res) => {
                    dispatch(removeSyncItem({ type: group, id: syncId }));
                  })
                  .catch((err) => console.log(err))
                  .finally(() => dispatch(decreaseItems()));
              }
            }
            break;
          case 'costs':
            const costsToSync = costs.find(
              (cost) => cost.id === syncId
            );
    
            if (costsToSync) {
              if (String(syncId).includes('NEW')) {
                dispatch(addNewCost(costsToSync))
                  .unwrap()
                  .then((res) => {
                    if (res.status === 200) {
                      dispatch(removeSyncItem({ type: group, id: syncId }));
                    }
                  })
                  .catch((err) => console.log(err))
                  .finally(() => dispatch(decreaseItems()));
              } else if (costsToSync.deleted === 1) {
                dispatch(deleteCost(costsToSync))
                  .unwrap()
                  .then((res) => {
                    if (res.status === 200) {
                      dispatch(removeSyncItem({ type: group, id: syncId }));
                    }
                  })
                  .catch((err) => console.log(err))
                  .finally(() => dispatch(decreaseItems()));
              } else {
                dispatch(updateCost(costsToSync))
                  .unwrap()
                  .then((res) => {
                    if (res.status === 200) {
                      dispatch(removeSyncItem({ type: group, id: syncId }));
                    }
                  })
                  .catch((err) => console.log(err))
                  .finally(() => dispatch(decreaseItems()));
              }
            }
            break;
          case 'surveys':
            dispatch(postSurvey(syncId))
              .unwrap()
              .then((res) => {
                const surveyEntry = Object.values(surveysEntries).find((entry) => entry.survey === syncId);

                if (surveyEntry) {
                  dispatch(postSurveyEntry(surveyEntry.id))
                    .unwrap()
                    .then((res) => {
                      dispatch(postRepairSync())
                        .unwrap()
                        .then((res) => {
                          dispatch(removeSyncItem({ type: group, id: syncId }));
                        })
                        .catch((err) => console.log(err));
                    })
                    .catch((error) => console.error(error));
                } else {
                  console.error('No entry found.');
                }
              })
              .catch((error) => console.error(error));
            break;
          case 'repairs':
            const repairToSync = Object.values(repairs).find(
              (repair) => repair.id === syncId
            );
    
            if (repairToSync) {
              dispatch(postRepairSync([repairToSync]))
                .unwrap()
                .then((res) => {
                  dispatch(removeSyncItem({ type: group, id: syncId }));
                })
                .catch((err) => console.log(err));
            }
            break;
        }
      })
    })
  }

  useEffect(() => {
    const totalItems = Object.values(sync).reduce(
      (acc, cur) => acc + cur.length,
      0
    );

    setNumOfItems(totalItems);

    return (() => {
      dispatch(resetItems());
    });
  }, [sync]);

  return (
    <Modal
      showModal={showSyncPopup}
      setShowModal={() => setShowSyncPopup(false)}
    >
      <View style={styles.container}>
        <Text variant='heading' size='lg' style={{ textAlign: 'center' }}>
          You have items to sync to the server
        </Text>
        <View style={styles.syncContainer}><Text>You have {numOfItems} item(s) to sync</Text></View>
        <View style={styles.buttonContainer}>
          <Button title={itemsToSync > 0 ? <ActivityIndicator color="#000000" /> : 'Sync Now'} onPress={handleSyncAll} />
        </View>
      </View>
    </Modal>
  );
};

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  syncContainer: {
    marginTop: 16
  },
  buttonContainer: {
    marginTop: 16
  }
});

export default SyncPopup;
